<template>
  <div>
    <ul class="home">
      <li class="item" v-for="(item, index) in data" :key="index">
        <div class="title" @click="goLink(item.game_url)">
          <img alt="" :src="item.icon" />
          <div class="title-right">
            <div class="name">
              {{ item.game_name }}
              <div class="is-hot" v-if="item.is_hot === 1">
                <img src="@/assets/hot.png" alt="" />
                <img src="@/assets/hot.png" alt="" />
                <img src="@/assets/hot.png" alt="" />
              </div>
            </div>
            <ul class="label">
              <li v-for="innerItem in item.label" :key="innerItem">
                {{ innerItem }}
              </li>
            </ul>
          </div>
        </div>
        <div class="content" @click="goLink(item.content_url)">
          {{ item.title }}
        </div>
        <img class="banner" alt="" :src="item.banner" @click="goLink(item.content_url)" />
        <div class="footer" @click="goLink(item.content_url)">
          <span>{{ item.read_num }}浏览</span> ·
          <span>{{ item.created_at }}</span>
          <span class="detail">查看详情</span>
        </div>
      </li>
    </ul>
    <div class="no-data" v-if="dataLoaded">暂无更多</div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'HomePage',
  data() {
    return {
      data: [],
      dataLoaded: false,
      page: 1,
      per_page: 5,
    };
  },
  mounted() {
    this.getData();
    this.loadMore();
    console.log(this.$route.query);
  },
  methods: {
    getData() {
      return new Promise((resolve) => {
        axios({
          url: 'https://adplatform.lc2game.com/information',
          params: {
            page: this.page,
            per_page: this.per_page,
            ...this.$route.query,
          },
        }).then((res) => {
          const resData = res.data;
          if (resData.errno === 1) {
            this.data.push(...resData.data.data);
            if (resData.data.data.length < this.per_page) {
              // 如果数据已经全部加载完毕，则停止滚动加载数据
              this.dataLoaded = true;
            }
            resolve();
          }
        });
      });
    },
    loadMore() {
      const threshold = 100; // 滚动条距离底部的阈值
      let isLoading = false; // 是否正在加载数据
      document.addEventListener('scroll', () => {
        if (this.dataLoaded) return;
        const scrollTop = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        const clientHeight = document.documentElement.clientHeight || window.innerHeight;

        const distanceToBottom = scrollHeight - scrollTop - clientHeight;
        // console.log(scrollTop, clientHeight, scrollHeight, distanceToBottom);
        if (distanceToBottom < threshold && !isLoading) {
          // 滚动条距离底部小于阈值，且未在加载数据
          isLoading = true;
          this.page += 1;
          this.getData().then(() => {
            // 加载完成后，将isLoading设置为false
            isLoading = false;
          });
        }
      });
    },

    goLink(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  padding: 0 5px;
  .item {
    padding: 20px 15px 20px;
    border-bottom: 1px solid #8d8d8d;
    .title {
      display: flex;
      cursor: pointer;
      img {
        width: 50px;
        height: 50px;
        border-radius: 10px;
        margin-right: 10px;
      }
      .title-right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          font-size: 16px;
          .is-hot {
            display: inline;
            img {
              width: 15px;
              height: 15px;
              margin-right: 3px;
            }
          }
        }
        .label {
          display: flex;
          li {
            margin-right: 5px;
            padding: 3px 5px;
            background-color: rgb(252, 204, 49);
            color: rgb(80, 74, 65);
            border-radius: 5px;
            font-size: 12px;
          }
        }
      }
    }
    .content {
      padding: 10px 0;
      font-weight: 500;
    }
    .banner {
      cursor: pointer;
      width: 100%;
      border-radius: 10px;
      margin-bottom: 5px;
    }
    .footer {
      color: #8d8d8d;
      font-size: 14px;
      .detail {
        float: right;
      }
    }
  }
}
.no-data {
  padding: 30px 0;
  background-color: #ededed;
  color: #8c8c8c;
  font-size: 16px;
  text-align: center;
}
</style>
