import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: '/home',
    children: [{
      path: '/home',
      name: '首页',
    }],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
